<template>
  <b-container class="py-4">
    <h2>Subscription Plans</h2>

    <b-tabs content-class="mt-3">
      <!-- Tab 1: All Plans -->
      <b-tab title="All Plans" active>
        <template v-if="pageloader">
          <b-row class="justify-content-center my-5">
            <b-col cols="auto">
              <b-spinner variant="primary" label="Loading..."></b-spinner>
            </b-col>
          </b-row>
        </template>

        <template v-else>
          <b-row class="justify-content-center">
            <b-col v-for="(plan, index) in plans" :key="plan.id" cols="12" sm="6" md="4">
              <b-card 
                class="text-center shadow-sm my-3 plan-card card-fixed-height"
                :class="{ 'active-plan-border': activePlan && activePlan.id === plan.id }"
              >
                <b-card-title class="h4 font-weight-bold text-center my-2">
                  <strong>{{ plan.name }}</strong>  
                </b-card-title>

                <b-card-subtitle class="mb-3 font-weight-bold">
                  <span v-if="parseFloat(plan.price) === 0">Free</span>
                  <span v-else>£{{ parseFloat(plan.price).toFixed(2) }} / month</span>
                </b-card-subtitle>

                <b-card-text class="plan-body">
                  <ul class="list-unstyled text-start mt-3">
                    <li v-for="(feature, idx) in visibleFeatures(plan.features)" :key="idx" style="text-align: start;">
                      ✅ {{ feature }}
                    </li>
                  </ul>

                  <b-button 
                    v-if="parseFeatures(plan.features).length > 10" 
                    @click="toggleExpand(plan)" 
                    variant="link" 
                    class="see-more mb-3"
                  >
                    {{ plan.expanded ? "See Less" : "See More" }}
                  </b-button>
                </b-card-text>

                <!-- Plan Status & Actions -->
                <div class="plan-footer">
                  <b-badge v-if="activePlan && activePlan.id === plan.id" variant="success" class="mb-2 mt-5">
                    Current Plan
                  </b-badge>
                  
                  <b-col v-if="activePlan && activePlan.id === plan.id && plan.name!='Basic'">
                        <template v-if="subscription.status!='active'">
                          <p style="color: red;">{{subscription.status  }}</p>

                        </template>
                    <p><strong>Next Renewal:</strong> {{ subscription?.end_date ? formatDate(subscription.end_date) : 'N/A' }}</p>
                  </b-col>

                  <b-button 
                  
                    v-if=" ($hasPermission('manage system settings')) &&( !activePlan || activePlan.id !== plan.id)"
                    class="choose-button" 
                    variant="success" 
                    @click="openPaymentModal(plan)"
                  >
                    {{ shouldUpgrade(plan, index) ? "Upgrade" : "Choose" }} {{ plan.name }}
                  </b-button>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </template>
      </b-tab>

      <!-- Tab 2: Placeholder for additional content -->
      <b-tab title="Payment Methods">
  <template v-if="paymentMethods.length">
    <b-list-group>
      <b-list-group-item v-for="(method, index) in paymentMethods" :key="index" class="d-flex justify-content-between align-items-center">
        <div>
          <strong>{{ method.brand.toUpperCase() }}</strong> •••• {{ method.last_4_digits }} (Exp: {{ method.exp_month }}/{{ method.exp_year }})
        </div>
        <b-button      
           v-if=" $hasPermission('manage system settings') "
        variant="danger" size="sm" @click="removePaymentMethod(method.id)">
          Remove
        </b-button>
      </b-list-group-item>
    </b-list-group>
  </template>

  <b-alert v-else show variant="warning">
    No payment methods found. Please add one.
  </b-alert>

  <b-button            v-if=" $hasPermission('manage system settings') "
  variant="success" class="mt-3" @click="openAddPaymentModal">
    Add Payment Method
  </b-button>
</b-tab>

<b-tab title="Domain Payment" @click="refreshDomainPayment" lazy no-body>
  <DomainPayments :key="domainPaymentKey"></DomainPayments>
</b-tab>

<b-tab title="Business Email Payments" @click="refreshMailPayment" lazy no-body>
  <BusinessMailPayments  :key="businessMailKey"></BusinessMailPayments >
</b-tab>

    </b-tabs>

    <!-- Payment Modal -->
    <b-modal id="paymentModal" title="Select Payment Method" hide-footer centered>
  <template v-if="paymentMethods.length">
    <b-form-group label="Select Payment Method">
      <b-form-select v-model="selectedPaymentMethod" :options="paymentMethodOptions" class="mb-3">
      </b-form-select>
    </b-form-group>

    <b-button 
      type="button" 
      variant="success" 
      class="mt-3 w-100" 
      @click="handlePayment" 
      :disabled="loading || !selectedPaymentMethod"
    >
      {{ loading ? "Processing..." : (parseFloat(selectedPlan?.price) > 0 ? "Subscribe" : "Get Free Plan") }}
    </b-button>
  </template>

  <template v-else>
    <b-alert show variant="warning" class="text-center">
      No payment methods found. Please add a payment method to proceed.
    </b-alert>

    <b-button 
      variant="success" 
      class="mt-3 w-100" 
      @click="openAddPaymentModal"
    >
      Add Payment Method
    </b-button>
  </template>
</b-modal>
    <b-modal id="addPaymentModal" title="Add Payment Method" hide-footer centered>
  <form @submit.prevent="handleAddPaymentMethod">
    <div ref="cardElement" class="card-element"></div>
    <b-button type="submit" variant="success" class="mt-3 w-100" :disabled="loading">
      {{ loading ? "Adding..." : "Add Payment Method" }}
    </b-button>
  </form>
</b-modal>

  </b-container>
</template>


<script>
import { loadStripe } from "@stripe/stripe-js";
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import DomainPayments from "./DomainPayments.vue";
import BusinessMailPayments from "./BusinessMailPayments .vue";

export default {
  name: "SubscriptionPlans",
  components:{
    DomainPayments,
    BusinessMailPayments
  },
  data() {
    return {
      domainPaymentKey: 0, // Initial key value
      businessMailKey:0,
      stripe: null,
      card: null,
      addcard:{
        title:null
      },
      loading: false,
      pageloader:true,
      plans: [],
      activePlan: null,
      selectedPlan: null,
      subscription:null,
      paymentMethods: [
       
      ],
      selectedPaymentMethod: '',

    };
  },
  computed: {
  paymentMethodOptions() {
    return this.paymentMethods.map(method => ({
      value: method.stripe_payment_method_id, // Use Stripe payment method ID
      text: `${method.brand.toUpperCase()} •••• ${method.last_4_digits} (Exp: ${method.exp_month}/${method.exp_year})`
    }));
  }
},
  async mounted() {
    let apikey=process.env.VUE_APP_STRIPE_PUBLISHED_KEY
    this.stripe = await loadStripe(apikey) // Set your Stripe public key

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Membership" },
      { title: "All Plans" }
    ]);

    this.getPlans();
    this.getPaymentMethods();

  },
  methods: {
    refreshDomainPayment() {
    this.domainPaymentKey++;
  },
  refreshMailPayment(){
    this.businessMailKey++
  },
    formatDate(date) {
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  },
    async getPlans() {
      try {
        this.pageloader=true
        const { data } = await ApiService.get("business/settings", "plans");
        this.plans = data.plans.map(plan => ({ ...plan, expanded: false }));
        this.subscription=data.subscription
        this.pageloader=false
        this.activePlan = data.subscription ? this.plans.find(plan => plan.id === data.subscription.subscription_plan_id) : null;
      } catch (error) {
        console.error("Error fetching plans:", error);
      }
    },

    async openPaymentModal(plan) {
  this.selectedPlan = plan;
  this.$bvModal.show("paymentModal");

  await this.$nextTick(); // Ensure modal is rendered before accessing refs
  this.selectedPaymentMethod = null; // Reset selected payment method

  // Destroy and reinitialize Stripe card field
  if (this.card) {
    this.card.unmount(); // Unmount previous instance
  }
  if (this.paymentMethods.length > 0) {
      this.selectedPaymentMethod = this.paymentMethods[0].stripe_payment_method_id;
    } else {
      this.selectedPaymentMethod = null; // No payment methods available
    }

  const elements = this.stripe.elements();
  this.card = elements.create("card");
  this.card.mount(this.$refs.cardElement);
},
openAddPaymentModal() {
  this.$bvModal.show("addPaymentModal");

  this.$nextTick(() => {
    if (this.card) {
      this.card.unmount();
    }

    const elements = this.stripe.elements();
    this.card = elements.create("card");
    this.card.mount(this.$refs.cardElement);
  });
},
async handleAddPaymentMethod() {
  this.loading = true;

  const { paymentMethod, error } = await this.stripe.createPaymentMethod({
    type: "card",
    card: this.card,
  });

  if (error) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.message,
    });
    this.loading = false;
    return;
  }

  try {
    // Send the payment method ID to the backend
    await ApiService.post("business/settings/payment-methods", {
      payment_method_id: paymentMethod.id,
    });

    Swal.fire({
      icon: "success",
      title: "Success!",
      text: "Payment method added successfully!",
      timer: 3000,
      showConfirmButton: false,
    });

    this.getPaymentMethods(); // Refresh the list
    this.$bvModal.hide("addPaymentModal");
  } catch (error) {
    console.error("Error adding payment method:", error);
    Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.error,
      });
    
  } finally {
    this.loading = false;
  }
},
async removePaymentMethod(paymentMethodId) {
  const confirmed = await Swal.fire({
    title: "Are you sure?",
    text: "Do you really want to remove this payment method?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, remove it!",
    cancelButtonText: "Cancel",
  });

  if (!confirmed.isConfirmed) return;

  try {
    await ApiService.delete(`business/settings/payment-methods/`+paymentMethodId);

    Swal.fire({
      icon: "success",
      title: "Removed!",
      text: "Payment method has been removed.",
      timer: 3000,
      showConfirmButton: false,
    });

    this.getPaymentMethods(); // Refresh list
  } catch (error) {
    console.error("Error removing payment method:", error);
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "Failed to remove payment method.",
    });
  }
},

async getPaymentMethods() {
  try {
    const { data } = await ApiService.get("business/settings/payment-methods");
    this.paymentMethods = data;
  } catch (error) {
    console.error("Error fetching payment methods:", error);
  }
},


async handlePayment() {
  this.loading = true;

  if (parseFloat(this.selectedPlan.price) === 0) {
    // Free plan - No payment required
    try {
      const { data } = await ApiService.post("business/settings/subscribe", {
        plan_id: this.selectedPlan.id
      });

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "You have successfully subscribed to the free plan!",
        timer: 3000,
        showConfirmButton: false
      });

      this.getPlans(); // Refresh plans
      this.$bvModal.hide("paymentModal");
    } catch (error) {
      console.error("Subscription Error:", error.response?.data?.error);
      
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.error,
      });
    } finally {
      this.loading = false;
    }

    return;
  }

  // Paid plan - Use selected payment method
  try {
    const { data } = await ApiService.post("business/settings/subscribe", {
      plan_id: this.selectedPlan.id,
      payment_method: this.selectedPaymentMethod,
    });

    Swal.fire({
      icon: "success",
      title: "Success!",
      text: "Subscription successful!",
      timer: 3000,
      showConfirmButton: false
    });

    this.getPlans(); // Refresh plans
    this.$bvModal.hide("paymentModal");
  } catch (error) {
    console.error("Subscription Error:", error.response?.data?.error);

    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.response?.data?.error,
    });
  } finally {
    this.loading = false;
  }
},

    parseFeatures(features) {
      try {
        if (Array.isArray(features)) return features;
        const parsed = JSON.parse(features);
        return Array.isArray(parsed) ? parsed : [];
      } catch (e) {
        return [];
      }
    },

    visibleFeatures(features) {
      const parsedFeatures = this.parseFeatures(features);
      return parsedFeatures.length > 10
        ? (this.plans.find(p => p.features === features)?.expanded ? parsedFeatures : parsedFeatures.slice(0, 10)) 
        : parsedFeatures;
    },

    toggleExpand(plan) {
      plan.expanded = !plan.expanded;
    },

    shouldUpgrade(plan, index) {
      return this.activePlan && this.plans[index - 1]?.id === this.activePlan.id;
    }
  }
};
</script>

<style>
.card-element {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
}
.card-fixed-height {
  position: relative;
  min-height: 450px;
  border-radius: 40px;
}

.choose-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
}
.plan-card {
  border: 2px solid transparent;
  transition: border-color 0.3s ease-in-out;
}

.active-plan-border {
  border: 2px solid #5D5BA0 !important; /* Green border for active plan */
  box-shadow: 0px 0px 10px rgba(40, 167, 69, 0.5); /* Optional glow effect */
}

</style>