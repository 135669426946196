<template>
    <div>
      <h4 class="mb-10 font-weight-bold text-dark">Renewal Payments</h4>
  
      <!-- Loader -->
      <div v-if="loading" class="text-center">
        <span class="spinner-border text-primary" role="status"></span>
        <p>Loading...</p>
      </div>
  
      <div v-else>
        <!-- Payment Method Dropdown -->
        <div class="mt-4">
          <label for="payment-method">Select Payment Method:</label>
          <template v-if="paymentMethods.length === 0">
            <b-alert show variant="warning">
                    No payment methods found. Please add one.
                </b-alert>
          </template>
          <select
            id="payment-method"
            v-model="selectedPaymentMethod"
            class="form-control form-control-solid form-control-lg mb-3"
          >
            <option v-if="paymentMethods.length === 0" disabled>No payment methods found</option>
            <option
              v-for="method in paymentMethods"
              :key="method.id"
              :value="method.stripe_payment_method_id"
            >
              {{ method.brand.toUpperCase() }} •••• {{ method.last_4_digits }} (Exp: {{ method.exp_month }}/{{ method.exp_year }})
            </option>
          </select>
        </div>
  
        <!-- Payment Button -->
        <div class="mt-4">
          <button
          v-if=" $hasPermission('manage system settings') "

            class="btn btn-primary"
            @click="initiateCustomPayment"
            :disabled="processing || !selectedPaymentMethod"
          >
            {{ processing ? "Processing..." : "Pay £30" }}
          </button>
        </div>
  
        <h3 class="mt-3">Payment History</h3>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Payment Date</th>
              <th>Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="payment in payments" :key="payment.id">
              <td>{{ formatDate(payment.created_at) }}</td>
              <td>£{{ payment.amount }}</td>
              <td>
                <span
                  class="px-2 py-1 rounded-lg text-white text-sm font-medium"
                  :style="getStatusStyle(payment.status)"
                >
                  {{ payment.status }}
                </span>
              </td>
            </tr>
            <tr v-if="payments.length === 0">
              <td colspan="3" class="text-center">No payment history found</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>
  
  <script>
  import ApiService from "@/core/services/api.service";
  import Swal from "sweetalert2";
  
  export default {
    name: "Domain",
    data() {
      return {
        payments: [],
        loading: true, // Loader state for data fetching
        processing: false, // Loader for button
        selectedPaymentMethod: null,
        paymentMethods: [],
      };
    },
    async mounted() {
      await this.fetchPaymentMethods();
      await this.fetchPayments();
      this.autoSelectPaymentMethod();
      this.loading = false;
    },
    methods: {
      async initiateCustomPayment() {
        if (!this.selectedPaymentMethod) {
          Swal.fire("Error", "Please select a payment method.", "error");
          return;
        }
  
        this.processing = true;
  
        try {
          const response = await ApiService.post("business/settings/custom-payment-foremailaccount", {
            amount: 30, 
            payment_method: this.selectedPaymentMethod,
          });
  
          if (response.data.success) {
            Swal.fire("Success", "Payment of £15 was successful!", "success");
            await this.fetchPayments();
          } else {
            Swal.fire("Error", "Payment failed. Please try again.", "error");
          }
        } catch (error) {
          console.error("Payment Error:", error);
          Swal.fire("Error", "An error occurred while processing the payment.", "error");
        } finally {
          this.processing = false;
        }
      },
      async fetchPaymentMethods() {
        try {
          const { data } = await ApiService.get("business/settings/payment-methods");
          this.paymentMethods = data;
        } catch (error) {
          console.error("Error fetching payment methods:", error);
        }
      },
      async fetchPayments() {
        try {
          const { data } = await ApiService.get("business/settings", "get-custom-payments-foremailaccount");
          this.payments = data.payments;
        } catch (error) {
          console.error("Error fetching payments:", error);
        }
      },
      autoSelectPaymentMethod() {
        if (this.paymentMethods.length > 0) {
          this.selectedPaymentMethod = this.paymentMethods[0].stripe_payment_method_id;
        }
      },
      formatDate(date) {
        return new Date(date).toLocaleDateString("en-GB");
      },
      getStatusStyle(status) {
        const statusColors = {
          paid: "background-color: green;",
          pending: "background-color: orange;",
          failed: "background-color: red;",
        };
        return statusColors[status] || "background-color: gray;";
      },
    },
  };
  </script>
  